import "../scss/app.scss";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faBuilding } from "@fortawesome/pro-light-svg-icons/faBuilding";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faKey } from "@fortawesome/pro-light-svg-icons/faKey";
import { faMapMarker } from "@fortawesome/pro-light-svg-icons/faMapMarker";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faXing } from "@fortawesome/free-brands-svg-icons/faXing";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";

const WAIT_WHEN_COMPLETE = 1500;
const WAIT_WHEN_EMPTY = 750;
const TYPE_FREQUENCY = 120;
const CURSOR_BLINK_FREQUENCY = 400;

library.add(
  faBuilding,
  faEnvelope,
  faXing,
  faGithub,
  faKey,
  faLinkedin,
  faMapMarker,
  faTimes
);
dom.watch();

const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const $ = function(query) {
  let res = document.querySelectorAll(query);
  return res.length > 1 ? res : res[0];
};

window.onload = function() {
  const $hiddenClassName = "hidden";
  const $mainContainer = $("#main-container");
  const $privacyContainer = $("#privacy-container");
  const $cursor = $("#cursor");
  const $textRotation = $("#textrotation");

  const colors = ["#000"];
  const words = $textRotation.innerHTML.replace(/\s{4}/g, "").split(",");
  shuffle(words);

  $textRotation.innerHTML = "";

  var letterCount = 1;
  var waiting = false;
  var isRemoving = false;

  $textRotation.setAttribute("style", "color:" + colors[0]);

  setInterval(() => {
    if (waiting) return;

    if (letterCount < 0 && isRemoving) {
      waiting = true;

      // Select new Word
      setTimeout(() => {
        var usedColor = colors.shift();
        colors.push(usedColor);
        var usedWord = words.shift();
        words.push(usedWord);

        $textRotation.setAttribute("style", "color:" + colors[0]);
        isRemoving = false;
        waiting = false;
      }, WAIT_WHEN_EMPTY);
    }

    // Word is full
    else if (letterCount === words[0].length + 1 && !isRemoving) {
      waiting = true;

      // Start Removing
      setTimeout(() => {
        isRemoving = true;
        waiting = false;
      }, WAIT_WHEN_COMPLETE);
    }
    // Type Word
    else {
      $textRotation.innerHTML = words[0].substring(0, letterCount);
      letterCount += isRemoving ? -1 : 1;
    }
  }, TYPE_FREQUENCY);

  // Underscore Rendering
  setInterval(() => {
    $cursor.classList.toggle("visually-hidden");
  }, CURSOR_BLINK_FREQUENCY);

  $("#privacy-button").addEventListener("click", function() {
    $mainContainer.classList.add($hiddenClassName);
    $privacyContainer.classList.remove($hiddenClassName);
  });

  $("#privacy-back-button").addEventListener("click", function() {
    $mainContainer.classList.remove($hiddenClassName);
    $privacyContainer.classList.add($hiddenClassName);
  });
};
